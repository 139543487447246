import Radio from '/components/elements/form/Radio'
import { useState, useEffect, useRef } from 'react'
import { useResponsive } from '@farfetch/react-context-responsive'
import isEmpty from 'lodash-es/isEmpty'
import capitalize from 'lodash-es/capitalize'
import toLower from 'lodash-es/toLower'
import lowerCase from 'lodash-es/lowerCase'
import { useSearchContext } from '/providers/SearchContext'
import axios from '/components/configs/axios'
import styles from './styles.module.scss'
import Grid from '/components/elements/Grid'
import classNames from 'classnames'
import CustomField from '/components/elements/form/CustomField'
import { translate } from '/components/helpers/translate'
import { useRouter } from 'next/router'

const HomeType = ({
  form: { setFieldValue },
  target = 'vacation-rentals',
  shadowRef,
  setFiltersActive,
  translations,
  ...props
}) => {
  const router = useRouter()
  const { search, setSearch } = useSearchContext()
  const [additionalFields, setAdditionalFields] = useState([])
  const responsive = useResponsive()

  const wrapperRef = useRef(null)
  const [show, setShow] = useState(false)
  const [checked, setChecked] = useState(search?.homeType)
  const [amenitiesChecked, setAmenitiesChecked] = useState(search?.amenities)
  const [types, setTypes] = useState([
    {
      value: 'exclusive',
      label: translations.exclusiveproperties[router.locale]
    },
    { value: 'villa', label: translations.villas[router.locale] },
    {
      value: 'condominium',
      label: translations.condos[router.locale]
    }
  ])
  const isMobile = responsive.lessThan.xs

  const replaceInArray = function (str) {
    return str && str.replace(/-/g, ' ')
  }
  const [label, setLabel] = useState([...search.homeType])

  //translationss

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleSubmit = () => {
    setSearch({ ...search, homeType: checked, amenities: amenitiesChecked })
    setFieldValue('homeType', [...checked])
    setFieldValue('amenities', [...amenitiesChecked])
    if (!isEmpty(checked) || !isEmpty(amenitiesChecked)) {
      setLabel([...checked, amenitiesChecked.map(replaceInArray).join(', ')])
    }
  }

  const handleChange = (e) => {
    var updatedList = [...checked]
    if (e.target.checked) {
      updatedList = [...checked, e.target.value]
    } else {
      updatedList.splice(checked.indexOf(e.target.value), 1)
    }
    setChecked(updatedList)
    setFieldValue('homeType', updatedList)
    handleSubmit()
  }
  const handleAmenitiesChange = (e) => {
    var updatedList = [...amenitiesChecked]
    if (e.target.checked) {
      updatedList = [...amenitiesChecked, e.target.value]
    } else {
      updatedList.splice(amenitiesChecked.indexOf(e.target.value), 1)
    }
    setAmenitiesChecked(updatedList)
    setFieldValue('amenities', updatedList)
  }

  const labelFunction = async () => {
    if (router.locale !== 'en' && window !== undefined) {
      if (!isEmpty(search.amenities)) {
        setLabel([
          ...search.homeType,
          search.amenities.map(replaceInArray).join(', ')
        ])
      } else {
        setLabel([
          ...search.homeType,
          search.amenities.map(replaceInArray).join(', ')
        ])
      }
    }
    if (isEmpty(search.amenities) && !isEmpty(search.homeType)) {
      if (router.locale !== 'en') {
        setLabel(await translate(search.homeType))
      } else {
        setLabel(search.homeType)
      }
    }
    if (isEmpty(search.amenities) && isEmpty(search.homeType)) {
      setLabel(translations.filters[router.locale])
    }
  }

  useEffect(() => {
    labelFunction()

    setAmenitiesChecked(search?.amenities)
    setChecked(search?.homeType)
  }, [search.amenities, search.homeType])

  useEffect(() => {
    if (target !== 'vacation-rentals') {
      axios.get('real-estate/property-type').then((res) => {
        const realEstateTypes = res.data[router.locale].map((type) => {
          return { value: lowerCase(type).replace(' ', '-'), label: type }
        })
        setTypes([
          {
            value: 'exclusive',
            label: translations.exclusiveproperties[router.locale]
          },
          ...realEstateTypes
        ])
      })
    }
  }, [router.locale])

  useEffect(() => {
    axios
      .get(
        target === 'vacation-rentals'
          ? 'amenities/tag'
          : 'real-estate/popular-amenities'
      )
      .then((res) => {
        if (target === 'vacation-rentals') {
          setAdditionalFields(res.data[router.locale])
        } else {
          setAdditionalFields({ amenities: res.data[router.locale] })
        }
      })
      .catch((err) => console.error(err))
    setAmenitiesChecked(search?.amenities)
  }, [router.locale])

  useEffect(() => {
    handleSubmit()
  }, [checked, amenitiesChecked])

  useEffect(() => {
    shadowRef && show
      ? shadowRef?.current?.classList.add('isVisible')
      : shadowRef?.current?.classList.remove('isVisible')
    setFiltersActive &&
      setFiltersActive(show || search.amenities || search.homeType)
  }, [shadowRef, show])

  useOutsideAlerter(wrapperRef)

  return (
    <div
      ref={wrapperRef}
      className={styles.field}
      role="group"
      aria-labelledby="bedrooms-group"
    >
      <CustomField
        aria-label="Select Home Type"
        type="text"
        readOnly
        angle
        variant={[responsive.lessThan.sm && 'full-width']}
        onClick={() => setShow(!show)}
        value={label}
        isActive={checked?.length !== 0 || amenitiesChecked?.length !== 0}
        {...props}
      />

      <div className={classNames(styles.field__radios, show && styles.isShown)}>
        <div className={styles.filters}>
          <div>
            <span className={styles.filters__label}>
              {translations.homeType[router.locale]}
            </span>

            {types.map((item, index) => (
              <Radio
                key={`type-${index}`}
                name="homeType"
                checkbox
                value={item.value}
                label={item.label}
                checked={checked?.includes(item.value)}
                onChange={(e) => {
                  handleChange(e)
                }}
                disabled={item.disabled}
              />
            ))}
          </div>

          {!isEmpty(additionalFields.amenities) && (
            <div className={styles.filters__amenities}>
              <span className={styles.filters__label}>
                {translations.amenities[router.locale]}
              </span>

              <Grid variant={[isMobile ? 'grid-2' : 'rows-5', 'rows-2xs']}>
                {additionalFields.amenities.map((item, index) => (
                  <Radio
                    key={`amenity-${index}`}
                    variant={['is-close', 'small']}
                    name="homeType"
                    checkbox
                    value={toLower(item)}
                    label={capitalize(item)}
                    checked={amenitiesChecked?.includes(toLower(item))}
                    onChange={(e) => handleAmenitiesChange(e)}
                    disabled={item.disabled}
                  />
                ))}
              </Grid>
            </div>
          )}

          {!isEmpty(additionalFields.offers) && (
            <div className={styles.filters__amenities}>
              <span className={styles.filters__label}>Promos</span>

              <Grid variant={[isMobile ? 'grid-2' : 'rows-5', 'rows-2xs']}>
                {additionalFields.offers.map((item, index) => (
                  <Radio
                    key={`amenity-${index}`}
                    variant={['is-close', 'small']}
                    name="homeType"
                    checkbox
                    value={toLower(item)}
                    label={capitalize(item)}
                    checked={amenitiesChecked?.includes(toLower(item))}
                    onChange={(e) => handleAmenitiesChange(e)}
                    disabled={item.disabled}
                  />
                ))}
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeType
