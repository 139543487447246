import styles from './styles.module.scss'
import IconPhone from '/public/icons/icon-phone.svg'
import Button from '../Button'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'

const Hamburger = ({
  trigger,
  children,
  setMenuOpen,
  changeState,
  translations
}) => {
  const responsive = useResponsive()
  const [open, setOpen] = useState(false)
  const body = typeof window !== 'undefined' && document.querySelector('body')
  const wrapperRef = useRef(null)
  const router = useRouter()

  const handleOpen = () => {
    setMenuOpen && setMenuOpen(!open)
    setOpen(!open)
    if (responsive.lessThan.xs) {
      !open
        ? body.classList.add('modal-open')
        : body.classList.remove('modal-open')
    }
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
          setMenuOpen && setMenuOpen(open)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => body.classList.remove('modal-open'), [router])

  useEffect(() => {
    changeState === true && handleOpen()
  }, [changeState])

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.menu, open && styles.isOpen)}
    >
      <div onClick={handleOpen} className={styles.menu__trigger}>
        {trigger}
      </div>

      <div className={styles.menu__content}>
        <div className={styles.menu__overflow}>{children}</div>

        {responsive.lessThan.xs && (
          <div className={styles.menu__footer}>
            <Button
              disabled={router.asPath === '/contact'}
              href="/contact"
              variant={['full-width']}
            >
              {translations.getInTouch[router.locale]}
            </Button>

            <Button
              prepend={<IconPhone />}
              variant={['full-width']}
              arrow={false}
              rel="nofollow"
              href="tel: +1 213-805-0840"
            >
              {translations.callUs[router.locale]}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Hamburger
