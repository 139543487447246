import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from '/components/configs/axios'
import { useRouter } from 'next/router'
import moment from 'moment'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import without from 'lodash-es/without'
import omit from 'lodash-es/omit'

const SearchContext = createContext('default')

const useSearchContext = () => {
  const context = useContext(SearchContext)

  if (context === undefined) {
    throw new Error('useSearchContext was used outside of its Provider')
  }

  return context
}

function SearchContextProvider({ children }) {
  const router = useRouter()

  const initialValues = {
    startDate: null,
    endDate: null,
    bedrooms: null,
    bathrooms: null,
    priceStart: null,
    priceEnd: null,
    amenities: [],
    homeType: [],
    location: [],
    listing: '',
    sorting: 'default'
  }

  const [isFirstVisit, setIsFirstVisit] = useState(true)
  const [listing, setListing] = useState()
  const [loading, setLoading] = useState(false)
  const [urlParams, setUrlParams] = useState({})
  const [reset, setReset] = useState(false)
  const [offsetable, setOffsetable] = useState(0)
  const [searchActive, setSearchActive] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [search, setSearch] = useState(initialValues)
  const [reserved, setReserved] = useState(false)
  const [requestStartDate, setRequestStartDate] = useState(moment())
  const [requestEndDate, setRequestEndDate] = useState(moment())
  const isCalendarPage = router.asPath === '/calendar'

  // for single page
  const [bedroomOptions, setBedroomOptions] = useState('')
  const [guests, setGuests] = useState(null)
  const activeRoute =
    router.pathname === '/vacation-rentals' ||
    router.pathname === '/real-estate/search' ||
    router.pathname === '/calendar'
  const searchRoute =
    router.pathname === '/calendar'
      ? 'calendar/search'
      : router.pathname === '/vacation-rentals'
      ? 'listings/search'
      : 'real-estate/search'
  const allPropertiesRoute =
    router.pathname === '/calendar'
      ? 'calendar/blocked-dates'
      : router.pathname === '/vacation-rentals'
      ? 'listings/index'
      : 'real-estate/properties'

  const hasInitialValuesInQuery = () => {
    return Object.keys(initialValues).some((key) => key in router.query)
  }

  // enable the search on the search/real estate pages
  useEffect(() => {
    !activeRoute && setSearchActive(false)
  }, [])

  useEffect(() => {
    if (router.asPath !== '/calendar') {
      setRequestStartDate(false)
      setRequestEndDate(false)
    } else {
      if (isEmpty(router.query)) {
        setSearch(initialValues)
      }
    }
  }, [router.asPath])

  //set the search if there are url params
  useEffect(() => {
    if (!isEmpty(router.query)) {
      const {
        startDate,
        endDate,
        bedrooms,
        bathrooms,
        priceStart,
        priceEnd,
        homeType,
        location,
        listing,
        amenities,
        sorting
      } = router.query

      if (activeRoute) {
        setSearch({
          ...search,
          location:
            typeof location === 'string'
              ? [location]
              : !isEmpty(location)
              ? [...location]
              : initialValues.location,
          amenities:
            typeof amenities === 'string'
              ? [amenities]
              : !isEmpty(amenities)
              ? [...amenities]
              : initialValues.amenities,
          homeType:
            typeof homeType === 'string'
              ? [homeType]
              : !isEmpty(homeType)
              ? [...homeType]
              : initialValues.homeType,
          priceStart: priceStart
            ? Number(priceStart)
            : initialValues.priceStart,
          priceEnd: priceEnd ? Number(priceEnd) : initialValues.priceEnd,
          bedrooms: bedrooms ? Number(bedrooms) : initialValues.bedrooms,
          bathrooms: bathrooms ? Number(bathrooms) : initialValues.bathrooms,
          startDate: startDate ? moment(startDate) : initialValues.startDate,
          endDate: endDate ? moment(endDate) : initialValues.endDate,
          listing: listing ? listing : initialValues.listing,
          sorting: sorting ? sorting : initialValues.sorting
        })
      }
    }
  }, [router.isReady, router.asPath])

  // build the url params
  useEffect(() => {
    let filtered = Object.keys(initialValues)
      .filter((key) => !isEqual(search[key], initialValues[key]))
      .reduce((obj, key) => {
        obj[key] = search[key]
        return obj
      }, {})

    const filteredQuery = omit(router.query, Object.keys(initialValues))

    if (filtered.startDate && moment.isMoment(filtered.startDate)) {
      filtered.startDate = filtered.startDate?.toISOString()
    }

    if (filtered.endDate && moment.isMoment(filtered.endDate)) {
      filtered.endDate = filtered.endDate?.toISOString()
    }
    if (isFirstVisit) {
      setUrlParams(filteredQuery)
    }

    if (!isEqual(urlParams, filtered) && activeRoute) {
      setUrlParams({ ...filtered })

      if (!isEmpty(urlParams)) {
        setUrlParams(filtered)
      }
    }

    setIsFirstVisit(false)
  }, [search, activeRoute])

  useEffect(() => setLoaded(true), [])

  useEffect(() => {
    const fetchAllProperties = async () => {
      setLoading(true)

      const params = {
        lang: router.locale,
        startDate:
          (isCalendarPage && requestStartDate && requestStartDate?._d) ||
          moment().format('YYYY-MM-DD'),
        endDate:
          (isCalendarPage && requestEndDate && requestEndDate?._d) ||
          moment().add(2, 'years').format('YYYY-MM-DD')
      }
      if (router.pathname === '/calendar') {
        delete params.lang
      }

      await axios
        .get(allPropertiesRoute, {
          params: params
        })
        .then((res) => {
          if (isEmpty(res.data)) {
            setListing(res.data)
          } else {
            setListing(res.data[router.locale])
          }
          setLoading(false)
        })

        .catch((error) => {
          if (error) {
            console.error(error)
            setListing([])
            setLoading(false)
          }
        })
    }

    if (
      isEmpty(urlParams) &&
      router.isReady &&
      !hasInitialValuesInQuery() &&
      loaded &&
      activeRoute
    ) {
      fetchAllProperties()
    }
  }, [
    reset,
    loaded,
    urlParams,
    router.isReady,
    activeRoute,
    router.asPath,
    router.locale,
    requestStartDate
  ])

  useEffect(() => {
    const searchProperties = async () => {
      const submittedStart = search.startDate && search.startDate.clone()

      const submittedEnd =
        submittedStart && search.endDate && search.endDate.clone()

      const submittedHomeType = without(search?.homeType, 'exclusive')

      setLoading(true)

      const params = {
        ...search,
        lang: router.locale,
        homeType: submittedHomeType,
        exclusive: search?.homeType.includes('exclusive') ? true : null,
        priceStart: search.priceStart || 0,
        priceEnd: search.priceEnd || 60000,
        startDate:
          isCalendarPage && requestStartDate?._d
            ? requestStartDate
            : submittedStart
            ? submittedStart?.subtract(offsetable, 'days')?.format('YYYY-MM-DD')
            : null,
        endDate:
          isCalendarPage && requestEndDate?._d
            ? requestEndDate
            : submittedEnd
            ? submittedEnd?.add(offsetable, 'days')?.format('YYYY-MM-DD')
            : null
      }

      await axios
        .get(searchRoute, {
          params: params
        })
        .then((res) => {
          if (isEmpty(res.data)) {
            setListing(res.data)
          } else {
            setListing(res.data[router.locale])
          }
          setLoading(false)
        })
        .catch((error) => {
          if (error) {
            console.error(error)
            setListing([])
            setLoading(false)
          }
        })
    }

    if (!isEmpty(urlParams) && activeRoute) {
      searchProperties()
    }
  }, [urlParams, activeRoute, requestStartDate, router.locale])

  return (
    <SearchContext.Provider
      value={{
        listing,
        setListing,
        search,
        setSearch,
        loading,
        initialValues,
        reset,
        setReset,
        urlParams,
        offsetable,
        setOffsetable,
        bedroomOptions,
        setBedroomOptions,
        guests,
        setGuests,
        searchActive,
        setSearchActive,
        setReserved,
        reserved,
        setRequestEndDate,
        setRequestStartDate
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { useSearchContext, SearchContext, SearchContextProvider }
