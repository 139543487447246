import styles from './styles.module.scss'
import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import CustomField from '../../../form/CustomField'
import IconBedroom from '/public/icons/icon-bedroom.svg'
import { useResponsive } from '@farfetch/react-context-responsive'
import Bedrooms from '../../../../custom/Bedrooms'
import { useSearchContext } from '../../../../../providers/SearchContext'
import { useRouter } from 'next/router'

const Bedroom = ({
  values,
  setFieldValue,
  shadowRef,
  setBedroomActive,
  custom,
  width,
  translations
}) => {
  const { search } = useSearchContext()
  const [show, setShow] = useState(false)
  const [value, setValue] = useState(search?.bedrooms || null)
  const wrapperRef = useRef(null)
  const responsive = useResponsive()
  const router = useRouter()

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useEffect(() => {
    setValue(search.bedrooms)
    setFieldValue('bedrooms', value)
  }, [search.bedrooms])

  useEffect(() => {
    setFieldValue('bedrooms', value)
  }, [value])

  // next section shadow overlay on click
  useEffect(() => {
    shadowRef && show
      ? shadowRef?.current?.classList.add('isVisible')
      : shadowRef?.current?.classList.remove('isVisible')
  }, [shadowRef, show])

  // change the card background when active
  useEffect(() => {
    setBedroomActive && setBedroomActive(show || search.bedrooms)
  }, [show, values])

  useOutsideAlerter(wrapperRef)

  return (
    <div
      ref={wrapperRef}
      className={styles.field}
      role="group"
      aria-labelledby="bedrooms-group"
    >
      <CustomField
        aria-label="Select Bedroom Option"
        type="text"
        readOnly
        angle
        variant={[responsive.lessThan.sm && 'full-width']}
        prependIcon={<IconBedroom width={26} height={26} />}
        width={width}
        onClick={() => setShow(!show)}
        isActive={value || show}
        value={
          value
            ? `${translations.bedrooms[router.locale]}: ${value}`
            : translations.bedrooms[router.locale]
        }
      />

      <div
        className={classNames(
          styles.field__radios,
          show && styles.isShown,
          custom ? styles.custom : ''
        )}
      >
        <Bedrooms value={value} setValue={setValue} />
      </div>
    </div>
  )
}

export default Bedroom
