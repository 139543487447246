import 'react-dates/initialize'
import {
  DayPickerRangeController,
  DayPickerSingleDateController
} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import IconNext from '/public/icons/icon-calendar-next.svg'
import IconPrevious from '/public/icons/icon-calendar-previous.svg'
import styles from './styles.module.scss'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useState, useEffect } from 'react'
import { withModifiers } from '../../../helpers/withModifiers'
import DropdownField from '../../DropdownField'
import Radio from '../Radio'
import Button from '../../Button'
import moment from 'moment'
import IconCalendar from '/public/icons/icon-calendar.svg'
import isEmpty from 'lodash-es/isEmpty'
import classNames from 'classnames'
import { useSearchContext } from '../../../../providers/SearchContext'
import { useRouter } from 'next/router'

// import { useRouter } from 'next/router'
// import 'moment/dist/locale/fr'
// import 'moment/dist/locale/es'
// import 'moment/dist/locale/ne'
// import 'moment/dist/locale/ro'

const Calendar = ({
  setStartDate,
  setEndDate,
  setOffsetDays,
  numberOfMonths,
  variant = [],
  clearable = false,
  yearJump = false,
  setDate,
  withOffset = false,
  blockedDates = false,
  type,
  disabled = false,
  // eslint-disable-next-line no-unused-vars
  date,
  translations,
  minimumNights,
  className,
  ...props
}) => {
  const responsive = useResponsive()
  // const router = useRouter()

  const router = useRouter()
  const { offsetable, search } = useSearchContext()

  const [focusedInput, setFocusedInput] = useState('startDate')
  const [initialMonth, setInitialMonth] = useState(moment())
  const [singleCalendarMonth, setSingleCalendarMonth] = useState(moment())

  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const [checked, setChecked] = useState(false)
  const [offset, setOffset] = useState(offsetable)

  const returnNearestDayUp = (offset = 0) => {
    let nearestDate
    blockedDates &&
      blockedDates.map((date) => {
        let diff = date.diff(search.startDate, 'days')
        if (diff > offset && !date.isSame(search.startDate, 'day')) {
          if (nearestDate) {
            if (date.diff(nearestDate, 'days') < 0) {
              nearestDate = date
            }
          } else {
            nearestDate = date
          }
        }
      })
    return nearestDate
  }

  const returnNearestDayDown = (offset = 0) => {
    let nearestDate
    blockedDates &&
      blockedDates.map((date) => {
        let diff = date.diff(search.endDate, 'days')
        if (diff < offset) {
          if (nearestDate) {
            if (date.diff(nearestDate, 'days') > 0) {
              nearestDate = date
            }
          } else {
            nearestDate = date
          }
        }
      })
    return nearestDate
  }

  const fireRefresh = () => {
    setOpacity(0)
    setTimeout(() => setLoading(true), 150)
    setTimeout(() => setLoading(false), 200)
    setTimeout(() => setOpacity(1), 900)
  }

  useEffect(() => {
    fireRefresh()
  }, [blockedDates])

  const defaultProps = {
    navPrev: (
      <IconPrevious
        onClick={() => {
          if (yearJump) {
            setOpacity(0)
            setTimeout(() => setOpacity(1), 900)
          }
        }}
      />
    ),
    navNext: (
      <IconNext
        onClick={() => {
          if (yearJump) {
            setOpacity(0)
            setTimeout(() => setOpacity(1), 900)
          }
        }}
      />
    ),

    daySize: responsive.lessThan.xs ? 30 : 35,
    numberOfMonths: numberOfMonths
      ? numberOfMonths
      : responsive.lessThan.xs
      ? 1
      : 2
  }

  const returnYears = () => {
    let years = []
    for (
      let i = Number(moment().format('YYYY'));
      i <= moment().add(2, 'years').format('YYYY');
      i++
    ) {
      years.push(
        <Radio
          variant={['underline']}
          onChange={(e) => {
            const currentMonth = initialMonth.clone()
            const monthDiff = currentMonth.format('M') - 1
            setChecked(Number(e.target.value))
            setDate(Number(e.target.value))
            setInitialMonth(moment(e.target.value).add(monthDiff, 'months'))
            fireRefresh()
          }}
          name="years"
          label={i}
          checked={checked === i}
          value={i}
          key={`Radio-${i}`}
        >
          {i}
        </Radio>
      )
    }
    return years
  }

  useEffect(() => {
    if (props.startDate === null) {
      setFocusedInput('startDate')
    }
    if (props.startDate && props.endDate === null) {
      setFocusedInput('endDate')
    }
    if (props.startDate && props.endDate) {
      setFocusedInput('startDate')
    }
  }, [props.startDate, props.endDate])

  const handleDatesChange = (startDate, endDate) => {
    // Functionality that focuses start date / end date
    // It will always have first click start date, second click end date

    if (props.startDate === null && props.endDate === null) {
      setStartDate(startDate)
      setTimeout(() => setFocusedInput('endDate'), 10)
    }

    if (props.startDate === null && props.endDate) {
      setStartDate(startDate)
      setTimeout(() => setFocusedInput('endDate'), 10)
    }

    if (props.startDate && props.endDate === null) {
      setEndDate(endDate)
      setTimeout(() => setFocusedInput('startDate'), 10)
    }

    if (props.startDate && props.endDate) {
      setEndDate(null)
      setStartDate(startDate)
      setTimeout(() => setFocusedInput('endDate'), 10)
    }

    if (
      (startDate && startDate?.isBefore(props.startDate, 'day')) ||
      startDate?.isAfter(props.endDate, 'day')
    ) {
      setEndDate(null)
      setStartDate(startDate)
      setTimeout(() => setFocusedInput('endDate'), 10)
    }
  }

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput)
  }

  const rangeControllerIsOutside = (day) => {
    if (blockedDates && !isEmpty(blockedDates)) {
      let BlockedDate = blockedDates.find(
        (item) => item.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
      )
      if (props.startDate && focusedInput === 'endDate') {
        let NextDay = day.clone().subtract(1, 'day')
        let EndBlocked =
          blockedDates &&
          blockedDates.find(
            (item) => item.format('YYYY-MM-DD') === NextDay.format('YYYY-MM-DD')
          )
        let nearestDate = returnNearestDayUp(-1)
        let booked = EndBlocked?.clone()

        if (nearestDate) {
          return (
            day?.isAfter(nearestDate, 'day') +
            day?.isSame(booked?.add(1, 'day'), 'day') +
            day?.isBefore(moment(), 'day')
          )
        }
      }
      if (
        props.endDate &&
        props.startDate === null &&
        focusedInput === 'startDate'
      ) {
        let nearestDate = returnNearestDayDown()

        if (nearestDate) {
          return (
            day.isBefore(nearestDate, 'day') + day.isSame(BlockedDate, 'day')
          )
        }
      }
      if (day.isSame(moment())) {
        return 0
      }

      return day.isSame(BlockedDate, 'day') + day.isBefore(moment(), 'day')
    }

    return day.isBefore(moment(), 'day')
  }

  const onPrevMonthClick = (month) => {
    if (setEndDate) {
      setInitialMonth(month)
      setChecked(Number(month.format('YYYY')))
      setDate(Number(month.format('YYYY')))
      setTimeout(() => setLoading(true), 150)
      setTimeout(() => setLoading(false), 200)
    }
  }

  const onNextMonthClick = (month) => {
    if (setEndDate) {
      setInitialMonth(month)
      setChecked(Number(month.format('YYYY')))
      setDate(Number(month.format('YYYY')))
      setTimeout(() => setLoading(true), 150)
      setTimeout(() => setLoading(false), 200)
    }
  }

  useEffect(() => {
    if (type === 'startDate' || type === 'endDate') {
      if (search.startDate) {
        setSingleCalendarMonth(moment(search.startDate.format('YYYY-MM-DD')))
        setLoading(true)
        setTimeout(() => setLoading(false), 200)
      }

      if (search.endDate) {
        setSingleCalendarMonth(moment(search.endDate.format('YYYY-MM-DD')))
        setLoading(true)
        setTimeout(() => setLoading(false), 200)
      }
    }
  }, [search.startDate, search.endDate])

  const dayPickerOutsideRange = (day) => {
    const defaultBlocked = day.isBefore(moment(), 'day')

    if (blockedDates && !isEmpty(blockedDates)) {
      if (type === 'startDate') {
        let BlockedDate =
          blockedDates &&
          blockedDates.find(
            (item) => item.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
          )

        if (search.endDate) {
          // checks so that you can't select a date that overlaps range of blocked dates

          return (
            defaultBlocked +
            day?.isSame(BlockedDate, 'day') +
            day?.isSame(search.endDate, 'day') +
            day?.isAfter(moment().add(3, 'years'))
          )
        }
        if (day.isSame(moment(), 'day') && !BlockedDate) {
          return 0
        }
        return (
          defaultBlocked +
          day?.isSame(BlockedDate, 'day') +
          day?.isAfter(moment().add(3, 'years'))
        )
      }

      if (type === 'endDate') {
        let NextDay = day.clone().subtract(1, 'day')
        let BlockedDate =
          blockedDates &&
          blockedDates.find(
            (item) => item.format('YYYY-MM-DD') === NextDay.format('YYYY-MM-DD')
          )

        let booked = BlockedDate?.clone()

        if (search.startDate) {
          // checks so that you can't select a date that overlaps range of blocked dates
          let nearestDate = returnNearestDayUp()

          if (nearestDate) {
            return (
              defaultBlocked +
              day?.isSame(booked?.add(1, 'day'), 'day') +
              day?.isSame(search.startDate, 'day') +
              day?.isAfter(moment().add(3, 'years'))
            )
          }

          return (
            defaultBlocked +
            BlockedDate +
            day?.isSame(search.startDate, 'day') +
            day?.isAfter(moment().add(3, 'years'))
          )
        }

        return (
          defaultBlocked +
          day?.isSame(booked?.add(1, 'day'), 'day') +
          day?.isAfter(moment().add(3, 'years'))
        )
      }
    }

    return defaultBlocked
  }

  return (
    <div
      className={classNames(
        className,
        withModifiers('calendar', [...variant, disabled && 'disabled'], styles)
      )}
    >
      {setEndDate ? (
        <>
          <div
            style={{ opacity: opacity }}
            className={styles.calendar__wrapper}
          >
            {!loading ? (
              yearJump ? (
                <DayPickerRangeController
                  {...defaultProps}
                  disabled={disabled}
                  onDatesChange={({ startDate, endDate }) => {
                    handleDatesChange(startDate, endDate)
                  }}
                  isOutsideRange={(day) => rangeControllerIsOutside(day)}
                  focusedInput={focusedInput}
                  onFocusChange={onFocusChange}
                  minDate={moment()}
                  onPrevMonthClick={(month) => onPrevMonthClick(month)}
                  onNextMonthClick={(month) => onNextMonthClick(month)}
                  initialVisibleMonth={() => initialMonth}
                  maxDate={moment().add(3, 'years')}
                  minimumNights={
                    (blockedDates &&
                      (returnNearestDayUp() === undefined ||
                        (props.startDate &&
                          returnNearestDayUp() &&
                          returnNearestDayUp().diff(props.startDate, 'days') >
                            minimumNights)) &&
                      minimumNights) ||
                    0
                  }
                  {...props}
                />
              ) : (
                <div>
                  <DayPickerRangeController
                    {...defaultProps}
                    disabled={disabled}
                    onDatesChange={({ startDate, endDate }) => {
                      handleDatesChange(startDate, endDate)
                    }}
                    maxDate={moment().add(3, 'years')}
                    isOutsideRange={(day) => rangeControllerIsOutside(day)}
                    focusedInput={focusedInput}
                    onFocusChange={onFocusChange}
                    minDate={moment()}
                    {...props}
                  />
                </div>
              )
            ) : (
              <div></div>
            )}
          </div>

          {withOffset && (
            <div className={styles.calendar__offsets}>
              {Array.from({ length: 4 }).map((currentElement, index) => (
                <Radio
                  value={index}
                  label={
                    index === 0
                      ? translations?.exactDates[router.locale]
                      : `± ${index} ${
                          !responsive.lessThan.xs
                            ? translations?.days[router.locale]
                            : ''
                        }`
                  }
                  onChange={(e) => {
                    setOffset(Number(e.target.value))
                    setOffsetDays(Number(e.target.value))
                  }}
                  name="offset"
                  checked={index === offset}
                  key={`Radio-${currentElement}-${index}`}
                  variant={['inline', 'boxed']}
                />
              ))}
            </div>
          )}

          {(clearable || yearJump) && (
            <div className={styles.calendar__footer}>
              {clearable && (
                <Button
                  type="button"
                  onClick={() => {
                    setStartDate(null)
                    setEndDate(null)
                    setFocusedInput('startDate')
                  }}
                  disabled={props.startDate === null && props.endDate === null}
                  variant={['label', 'gray']}
                  arrow={false}
                >
                  {responsive.lessThan.xs
                    ? translations?.clear[router.locale]
                    : translations?.clearDates[router.locale]}
                </Button>
              )}

              <div className={styles.calendar__select}>
                <div className={styles.calendar__label}>
                  {translations.skip[router.locale]}
                </div>

                <DropdownField
                  variant={['top', 'dropdown', 'accent', 'p-xs']}
                  footer={false}
                  name="yearChange"
                  width={9.4}
                  disabled={disabled}
                  prependIcon={<IconCalendar />}
                  value={initialMonth.format('YYYY')}
                >
                  <div>{returnYears()}</div>
                </DropdownField>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          style={{ opacity: opacity, minHeight: '250px' }}
          className={withModifiers(
            'calendar__single',
            [disabled && 'disabled'],
            styles
          )}
        >
          {loading ? (
            <DayPickerSingleDateController
              {...defaultProps}
              transitionDuration={0}
              onDateChange={(date) => {
                setStartDate(date)
              }}
              initialVisibleMonth={() => singleCalendarMonth}
              date={props.startDate}
              focused={true}
              isOutsideRange={(day) => dayPickerOutsideRange(day)}
            />
          ) : (
            <div>
              <DayPickerSingleDateController
                {...defaultProps}
                transitionDuration={0}
                onDateChange={(date) => {
                  setStartDate(date)
                }}
                initialVisibleMonth={() => singleCalendarMonth}
                date={props.startDate}
                focused={true}
                isOutsideRange={(day) => dayPickerOutsideRange(day)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const withCalendar = (Component) =>
  function Wrapper(props) {
    const [date, setDate] = useState(0)

    return <Component date={date} setDate={setDate} {...props} />
  }

export default withCalendar(Calendar)
