import Button from '../../../../elements/Button'
import Container from '../../../../elements/Container'
import Dropdown from '../../../../elements/Dropdown'
import Link from 'next/link'
import IconSearch from '/public/icons/icon-search.svg'
import Column from '../../../../layout/Column'
import Columns from '../../../../layout/Columns'
import Rentals from './components/Rentals'
import DateSearch from '../../../../elements/DateSearch'
import Menu from './components/Menu'
import { useResponsive } from '@farfetch/react-context-responsive'
import styles from './styles.module.scss'
import Logo from '/public/logo.svg'
import Hamburger from '../../../../elements/Hamburger'
import { useEffect, useState } from 'react'
import { useSearchContext } from '../../../../../providers/SearchContext'
import { useRouter } from 'next/router'
import { withModifiers } from '../../../../helpers/withModifiers'
import axios from '/components/configs/axios'

const Navigation = ({ translations }) => {
  const responsive = useResponsive()
  const lessThan = responsive.lessThan
  const [active, setActive] = useState(false)
  const [realEstateActive, setRealEstateActive] = useState(false)
  const { searchActive } = useSearchContext()
  const router = useRouter()
  const [closeClick, setCloseClick] = useState(false)
  const [closeEstateClick, setCloseEstateClick] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [locations, setLocations] = useState(false)
  const [realEstateLocations, setRealEstateLocations] = useState(false)

  useEffect(() => {
    axios
      .get('listings/neighbourhood')
      .then((response) => {
        const locations = response.data.map((location) => ({
          title: location.title,
          items: location.options.map((option) => ({
            title: option.label,
            link: option.link
          }))
        }))

        setLocations(locations)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [router.locale])

  useEffect(() => {
    axios
      .get('real-estate/neighbourhood')
      .then((response) => {
        const locations = response.data.map((location) => ({
          title: location.title,
          items: location.options.map((option) => ({
            title: option.label,
            link: `/real-estate/search?location=${option.value}`
          }))
        }))

        setRealEstateLocations(locations)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [router.locale])

  useEffect(() => setLoaded(true), [])

  return (
    <div className={styles.navigation}>
      <Container variant={['-xxl']}>
        <Columns variant={['-distribute-between', 'no-gutter']}>
          <Column
            className={styles.column}
            variant={['no-padding', '-align-center']}
          >
            <Link href="/">
              <a
                title="St Martin Sotheby's logo"
                className={withModifiers(
                  'logo',
                  [router.pathname === '/' && 'current'],
                  styles
                )}
              >
                <Logo width={lessThan.xs ? 170 : lessThan.sm ? 202 : 254} />
              </a>
            </Link>
          </Column>

          {!lessThan.md && (
            <Column className={styles.columnRight}>
              <div className={styles.navigation__items}>
                <Dropdown
                  handleClose={closeClick}
                  active={active}
                  custom
                  title={translations.rentals[router.locale]}
                >
                  {loaded && (
                    <Rentals
                      translations={translations}
                      setCloseClick={setCloseClick}
                      setActive={setActive}
                      locations={locations}
                    />
                  )}
                </Dropdown>

                <Dropdown
                  handleClose={closeEstateClick}
                  active={realEstateActive}
                  custom
                  href="/real-estate"
                  title={translations.realEstate[router.locale]}
                >
                  <Rentals
                    translations={translations}
                    setCloseClick={setCloseEstateClick}
                    setActive={setRealEstateActive}
                    locations={realEstateLocations}
                    defaultUrl="real-estate/search"
                  />
                </Dropdown>

                <Link href="/guest-experience">
                  <a
                    className={withModifiers(
                      'navigation__item',
                      [
                        (router.asPath === '/guest-experience' ||
                          (!router.asPath === '/guest-experience' &&
                            realEstateActive)) &&
                          'active'
                      ],
                      styles
                    )}
                    href="/real-estate"
                    title="Real Estate"
                  >
                    {translations.guestExperience[router.locale]}
                  </a>
                </Link>

                <Link href="/stories">
                  <a
                    className={withModifiers(
                      'navigation__item',
                      [router.asPath === '/stories' && 'active'],
                      styles
                    )}
                  >
                    {translations.stories[router.locale]}
                  </a>
                </Link>

                <Dropdown
                  custom
                  active={searchActive}
                  prepend={
                    <IconSearch
                      width={lessThan.xs ? 18 : lessThan.sm ? 18 : 24}
                    />
                  }
                  arrow={false}
                  title={translations?.search[router.locale]}
                >
                  <DateSearch
                    form={['dates', 'bedrooms', 'listings']}
                    translations={translations}
                    custom
                    button
                  />
                </Dropdown>
              </div>
            </Column>
          )}

          <Column
            variant={[
              'no-padding',
              'justify-right',
              'align-middle',
              lessThan.md && '-static'
            ]}
            className={styles.column}
          >
            {lessThan.md && !lessThan.xs && (
              <Dropdown
                custom
                active={searchActive}
                prepend={
                  <IconSearch
                    width={lessThan.xs ? 18 : lessThan.sm ? 18 : 24}
                  />
                }
                arrow={false}
                title={translations?.search[router.locale]}
              >
                <DateSearch
                  translations={translations}
                  form={['dates', 'bedrooms', 'listings']}
                  button
                />
              </Dropdown>
            )}

            {lessThan.xs && (
              <Hamburger
                translations={translations}
                trigger={
                  <IconSearch
                    className={styles.navigation__search}
                    width={18}
                  />
                }
              >
                <DateSearch
                  translations={translations}
                  form={['dates', 'bedrooms', 'listings']}
                  button
                />
              </Hamburger>
            )}

            {!lessThan.xs && lessThan.md && (
              <div className={styles.navigation__dot}></div>
            )}

            {!lessThan.xs && (
              <Button
                className={styles.navigation__button}
                disabled={router.asPath === '/contact'}
                href="/contact"
                arrow={false}
              >
                {translations?.getInTouch[router.locale]}
              </Button>
            )}

            {lessThan.md && <div className={styles.navigation__dot}></div>}

            {lessThan.md && (
              <Menu
                locations={locations}
                realEstateLocations={realEstateLocations}
                setCloseClick={setCloseClick}
                translations={translations}
              />
            )}
          </Column>
        </Columns>
      </Container>
    </div>
  )
}

export default Navigation
