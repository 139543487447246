import axios from 'axios'

export const translate = async (text, locale = 'en') => {
  try {
    const response = await axios.post(
      // 'http://localhost:3000/api/translate',
      // eslint-disable-next-line no-undef
      `${process.env.NEXT_PUBLIC_DOMAIN_URL}/api/translate`,
      null,
      {
        params: { text, locale: locale }
      }
    )

    const translatedText = response.data.translation
    return translatedText
  } catch (err) {
    console.error(err)
    // Return the original text if there's an error
    return text
  }
}
