import React from 'react'
import Bar from './components/Bar'
import Navigation from './components/Navigation'
import styles from './styles.module.scss'

const Header = ({ translations }) => (
  <header className={styles.header}>
    {translations && <Bar translations={translations} />}

    {translations && <Navigation translations={translations} />}
  </header>
)

export default React.memo(Header)
