const rentals = {
  main: [
    {
      title: {
        en: 'Exclusive Properties',
        fr: 'Propriétés exclusives',
        es: 'Propiedades Exclusivas',
        nl: 'Exclusieve eigenschappen'
      },
      link: '/vacation-rentals?homeType=exclusive',
      onBoth: true
    },
    {
      title: {
        en: 'New Listings',
        fr: 'Nouvelles annonces',
        es: 'Nuevos listados',
        nl: 'Nieuwe aanbiedingen'
      },
      link: '/vacation-rentals?sorting=listings'
    },
    {
      title: {
        en: 'Beachfront',
        fr: 'Propriétés en bord de mer',
        es: 'Propiedades frente al mar',
        nl: 'Eigenschappen aan het strand'
      },
      link: '/vacation-rentals?amenities=beachfront',
      onlyVacationRentals: true
    },
    {
      title: {
        en: 'villas',
        fr: 'villas',
        es: 'villas',
        nl: "villa's"
      },
      link: '/vacation-rentals?homeType=villa',
      onBoth: true
    },
    {
      title: {
        en: 'Condos',
        fr: 'Copropriétés',
        es: 'Condominios',
        nl: 'Appartementen'
      },
      link: '/vacation-rentals?homeType=condominium',
      onBoth: true
    }
  ]
}

export default rentals
