import { Field, ErrorMessage } from 'formik'
import styles from './styles.module.scss'
import ArrowDown from '/public/icons/icon-calendar-caret.svg'
import classNames from 'classnames'
import { useRef, useState, useEffect } from 'react'
import { useResponsive } from '@farfetch/react-context-responsive'
import { withModifiers } from '../../../helpers/withModifiers'

const CustomField = ({
  angle,
  prependIcon,
  appendIcon,
  optional = false,
  isActive,
  prepend,
  width,
  variant = [],
  children,
  label,
  className,
  error,
  valid,
  withError = false,
  formik = true,
  ...props
}) => {
  const ref = useRef(null)
  const icons = useRef(null)
  const [padding, setPadding] = useState(0)
  const responsive = useResponsive()

  useEffect(() => {
    setPadding(ref.current?.offsetWidth)
  }, [ref, prepend])

  return (
    <div
      style={
        optional && variant.includes('optional')
          ? { marginBottom: responsive.lessThan.sm ? '0.8rem' : '1.1rem' }
          : undefined
      }
      className={classNames(className, withModifiers('field', variant, styles))}
    >
      {label && (
        <label
          style={{ opacity: props.disabled ? 0.5 : 1 }}
          className={styles.field__label}
        >
          {label}
        </label>
      )}
      {formik ? (
        props.as === 'select' ? (
          <Field
            className={withModifiers(
              'field__input',
              [isActive && 'is-active'],
              styles
            )}
            {...props}
            style={{
              paddingLeft: appendIcon
                ? variant.includes('simple')
                  ? '33px'
                  : '42px'
                : prepend && `${padding + 15}px`,
              width:
                !variant.includes('full-width') && width
                  ? `${width}rem`
                  : undefined,
              paddingRight: icons ? icons.current?.offsetWidth + 12 : undefined
            }}
          >
            {children}
          </Field>
        ) : (
          <Field
            className={withModifiers(
              'field__input',
              [isActive && 'is-active', error && 'error', valid && 'valid'],
              styles
            )}
            {...props}
            style={{
              paddingLeft: appendIcon
                ? variant.includes('simple')
                  ? '33px'
                  : '42px'
                : prepend && `${padding + 15}px`,
              width:
                !variant.includes('full-width') && width
                  ? `${width}rem`
                  : undefined,
              paddingRight: icons
                ? (icons.current?.offsetWidth || 0) + 12
                : undefined
            }}
          />
        )
      ) : (
        <input
          className={withModifiers(
            'field__input',
            [isActive && 'is-active', error && 'error', valid && 'valid'],
            styles
          )}
          {...props}
          style={{
            paddingLeft: appendIcon
              ? variant.includes('simple')
                ? '33px'
                : '42px'
              : prepend && `${padding + 15}px`,
            width:
              !variant.includes('full-width') && width
                ? `${width}rem`
                : undefined,
            paddingRight: icons
              ? (icons.current?.offsetWidth || 0) + 12
              : undefined
          }}
        ></input>
      )}

      {appendIcon && <div className={styles.field__apend}>{appendIcon}</div>}

      {prepend && (
        <div ref={ref} className={styles.field__prepend}>
          {prepend}
        </div>
      )}

      {(angle || prependIcon) && (
        <div ref={icons} className={styles.field__icons}>
          {prependIcon && (
            <div className={styles.field__icon}>{prependIcon}</div>
          )}

          {angle && <ArrowDown className={styles.field__angle} />}
        </div>
      )}

      {optional && variant.includes('optional') && (
        <label className={styles.field__optional}>* Optional</label>
      )}

      {props.as !== 'select' && children}

      {(variant.includes('underline') || withError) && (
        <>
          <ErrorMessage name={props.name}>
            {(msg) => <div className={styles.field__error}>{msg}</div>}
          </ErrorMessage>
        </>
      )}
    </div>
  )
}

export default CustomField
