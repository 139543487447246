import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { useState, useEffect, useRef } from 'react'
import CustomField from '../../../form/CustomField'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useSearchContext } from '/providers/SearchContext'
import styles from './styles.module.scss'
import classNames from 'classnames'
// import { translate } from '/components/helpers/translate'

const Price = ({
  form: { setFieldValue },
  setPriceActive,
  shadowRef,
  price,
  translation,
  ...props
}) => {
  const [value, setValue] = useState({ min: 0, max: price })
  const { search } = useSearchContext()
  const [show, setShow] = useState(false)
  const wrapperRef = useRef(null)
  const responsive = useResponsive()

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleChange = (value) => {
    value.min = Number(value.min.toFixed(2))
    value.max = Number(value.max.toFixed(2))
    setValue(value)
    setFieldValue('priceStart', value.min)
    setFieldValue('priceEnd', value.max)
  }

  useEffect(() => {
    if (search.priceStart !== null || search.priceEnd !== null) {
      setValue({ min: search.priceStart, max: search.priceEnd })
    }
  }, [search.priceEnd, search.priceStart])

  useEffect(() => {
    setPriceActive && setPriceActive(value.max !== price || value.min !== 0)
  }, [value])

  useEffect(() => {
    setFieldValue('priceStart', value.min)
    setFieldValue('priceEnd', value.max)
  }, [value])

  useEffect(() => {
    shadowRef && show
      ? shadowRef?.current?.classList.add('isVisible')
      : shadowRef?.current?.classList.remove('isVisible')
  }, [shadowRef, show])

  useOutsideAlerter(wrapperRef)

  return (
    <div ref={wrapperRef} className={styles.field}>
      <CustomField
        type="text"
        aria-label="Select Price Range"
        readOnly
        angle
        variant={[responsive.lessThan.sm && 'full-width']}
        onClick={() => setShow(!show)}
        isActive={value.max !== price || value.min !== 0}
        value={
          value.max !== price || value.min !== 0
            ? `${value.min} K - ${value.max} K`
            : translation
        }
        {...props}
      />

      <div className={classNames(styles.field__radios, show && styles.isShown)}>
        <div className="range">
          <InputRange
            maxValue={price}
            minValue={0.0}
            value={value}
            formatLabel={() => null}
            step={0.1}
            onChange={(value) => {
              if (value.min >= 0.0 && value.max <= price) {
                handleChange(value)
              }
            }}
          />

          <div className="range__labels">
            <div>{`${value.min} K`}</div>
            <div>{`${value.max} K`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Price
