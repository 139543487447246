/* eslint-disable react/prop-types */
import classNames from 'classnames'

const Column = ({
  children,
  variant = [],
  spans,
  className,
  style,
  columnRef
}) => (
  <div
    style={style}
    className={classNames(
      'column',
      className,
      ...variant,
      spans && `-spans-${spans}`
    )}
    ref={columnRef}
  >
    {children}
  </div>
)

export default Column
