import Calendar from '../../../form/Calendar'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { withModifiers } from '../../../../helpers/withModifiers'
import { useState, useRef, useEffect } from 'react'
import CustomField from '../../../form/CustomField'
import { useResponsive } from '@farfetch/react-context-responsive'
import IconCalendar from '/public/icons/icon-calendar.svg'
import { useSearchContext } from '../../../../../providers/SearchContext'
import { useRouter } from 'next/router'

const Dates = ({
  form: { setFieldValue, values },
  shadowRef,
  setCalendarActive,
  variant = [],
  custom,
  icon = false,
  translations
}) => {
  const { search, reset, initialValues, setOffsetable } = useSearchContext()
  const router = useRouter()
  const [showCalendar, setShowCalendar] = useState(false)
  const wrapperRef = useRef(null)
  const responsive = useResponsive()
  const [startDate, setStartDate] = useState(search?.startDate)
  const [loaded, setLoaded] = useState(false)
  const [endDate, setEndDate] = useState(search?.endDate)

  const startDateString = startDate && startDate.format('MMM D')
  const endDateString = endDate && endDate.format('MMM D')

  const dateRangeString =
    startDate && endDate
      ? `${startDateString} - ${endDateString}`
      : translations.dates[router.locale]

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCalendar(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    setStartDate(search.startDate)
    setEndDate(search.endDate)
  }, [search.startDate, search.endDate])

  useEffect(() => {
    setFieldValue('startDate', startDate)

    setFieldValue('endDate', endDate)
  }, [startDate, endDate])

  // next section shadow overlay on click
  // to be moved in the parent dateSearch for code improve
  useEffect(() => {
    shadowRef && showCalendar
      ? shadowRef?.current?.classList.add('isVisible')
      : shadowRef?.current?.classList.remove('isVisible')
  }, [shadowRef, showCalendar])

  useEffect(() => {
    reset && setStartDate(initialValues.startDate)
    reset && setEndDate(initialValues.endDate)
  }, [reset])

  // change the card background when active
  useEffect(() => {
    setCalendarActive &&
      setCalendarActive((search.startDate && search.endDate) || showCalendar)
  }, [showCalendar, values])

  useEffect(() => setLoaded(true), [])

  return (
    <div ref={wrapperRef} className={withModifiers('dates', variant, styles)}>
      <CustomField
        variant={[...variant, responsive.lessThan.sm && 'full-width']}
        aria-label="Select Dates"
        angle
        readOnly
        isActive={(startDate && endDate) || showCalendar}
        name="calendar"
        width={
          startDate && endDate
            ? responsive.lessThan.md
              ? 9.5
              : responsive.lessThan.sm
              ? 11
              : 13.5
            : 9.56
        }
        prependIcon={icon && <IconCalendar width={26} height={26} />}
        value={dateRangeString}
        onClick={() => {
          setShowCalendar(!showCalendar)
        }}
      />

      <div
        className={classNames(
          styles.dates__wrapper,
          showCalendar && styles.isActive,
          custom ? styles.custom : ''
        )}
      >
        {loaded && (
          <Calendar
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setOffsetDays={setOffsetable}
            endDate={endDate}
            translations={translations}
            withOffset
          />
        )}
      </div>
    </div>
  )
}

export default Dates
