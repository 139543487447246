import Dropdown from '../../../../elements/Dropdown'
import styles from './styles.module.scss'
import Container from '../../../../elements/Container'
import IconHeart from '/public/icons/icon-favourite.svg'
import Currency from './components/Currency'
// import Measure from './components/Measure'
import { useResponsive } from '@farfetch/react-context-responsive'
import IconPhone from '/public/icons/icon-phone.svg'
import { useCurrencyContext } from '/providers/currencyProvider'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Languages from './components/Languages'
import Link from 'next/link'
import { withModifiers } from '../../../../helpers/withModifiers'

const Bar = ({ translations }) => {
  const responsive = useResponsive()
  const isMobile = responsive.lessThan.xs
  const isTablet = responsive.lessThan.sm
  const { selectedCurrency } = useCurrencyContext()
  const router = useRouter()
  const [closeClick, setCloseClick] = useState(false)

  return (
    <Container variant={['-xxl']}>
      <div className={styles.bar}>
        <span className={styles.bar__phone}>
          {isTablet ? (
            <IconPhone />
          ) : (
            `${translations.assistance[router.locale]}: `
          )}
          <a rel="nofollow" href="tel: +1 213-805-0840">
            +1 213-805-0840
          </a>
        </span>

        {!isMobile && <div className={styles.bar__dot}></div>}
        <Languages />
        <div className={styles.bar__dot}></div>

        <Dropdown
          handleClose={closeClick}
          disabled={!router.asPath.includes('real-estate')}
          title={`${isMobile ? '' : translations?.currency[router.locale]} ${
            selectedCurrency?.label
          }`}
          translations={translations}
        >
          <Currency translation={translations} setCloseClick={setCloseClick} />
        </Dropdown>

        {/* <div className={styles.bar__dot}></div>

        <Dropdown disabled title="Imperial">
          <Measure />
        </Dropdown> */}

        <div className={styles.bar__dot}></div>

        <Link href="/favourites">
          <a
            className={withModifiers(
              'bar__item',
              [router.asPath === '/favourites' && 'active'],
              styles
            )}
          >
            {!isTablet && translations.myFavourites[router.locale]}
            <IconHeart width={16} height={16} className={styles.bar__icon} />
          </a>
        </Link>
      </div>
    </Container>
  )
}

export default Bar
