import Image from 'next/image'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'

const LazyImage = ({ src, index, width, height, alt, ...props }) => {
  const isLayout = props.layout === 'fill'
  const [loaded, setLoaded] = useState(false)

  useEffect(() => setLoaded(true), [])

  if (loaded) {
    return (
      <div
        style={{
          position: !isLayout ? 'relative' : 'static',
          display: props.sizes ? 'block' : 'flex'
        }}
        className={styles.image}
      >
        <Image
          width={!isLayout && (width || 590)}
          height={!isLayout && (height || 393)}
          src={src}
          quality={90}
          alt={alt || `Property presentation image ${index}`}
          {...props}
        />
      </div>
    )
  }

  return
}

export default LazyImage
