import { Field } from 'formik'
import styles from './styles.module.scss'
import { withModifiers } from '../../../helpers/withModifiers'
import IconCheck from '/public/icons/icon-checkbox-check.svg'

const Radio = ({
  checked,
  value,
  label,
  name,
  onChange,
  onClick,
  variant = [],
  checkbox = false,
  disabled = false
}) => {
  const isWhite = variant.includes('white')
  return (
    <label
      className={withModifiers(
        'radio',
        [...variant, disabled && 'disabled'],
        styles
      )}
    >
      <Field
        checked={checked}
        type={checkbox ? 'checkbox' : 'radio'}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange && onChange}
        onClick={onClick && onClick}
      />
      {!variant.includes('boxed') && (
        <span className={styles.radio__checkmark}>
          {isWhite && <IconCheck width={15} height={13} />}
        </span>
      )}

      <span className={styles.radio__label}>{label}</span>
    </label>
  )
}

export default Radio
