import styles from './styles.module.scss'
import { Field } from 'formik'

const Bedrooms = ({
  value,
  setValue,
  bedroom = false,
  items = 8,
  name = 'bedrooms'
}) =>
  Array.from({ length: items }).map((currentElement, index) => (
    <label key={index + 1} className={styles.radio}>
      <Field
        type="radio"
        onChange={(e) => {
          setValue(parseInt(e.target.value))
        }}
        checked={value === index + 1}
        name={name}
        value={index + 1}
      />

      <span className={styles.radio__content}>
        {!bedroom && index === 7 ? '8+' : index + 1}
      </span>
    </label>
  ))

export default Bedrooms
