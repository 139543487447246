import { Formik, Form } from 'formik'
import styles from '../styles.module.scss'
import Button from '../../../../../../elements/Button'
import { useState } from 'react'
import Radio from '../../../../../../elements/form/Radio'
import { useCurrencyContext } from '../../../../../../../providers/currencyProvider'
import { useRouter } from 'next/router'

const Currency = ({ setCloseClick, translation }) => {
  const { currency, setCurrency, currencies, defaultValue } =
    useCurrencyContext()
  const router = useRouter()
  const [checked, setChecked] = useState(currency)

  const handleChange = (e, setFieldValue) => {
    setChecked(e.target.value)
    setFieldValue('currency', e.target.value)
  }

  const executeHide = () => {
    setCloseClick(true)
    setTimeout(() => setCloseClick(false), 20)
  }

  return (
    <Formik
      initialValues={{
        currency: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        setCurrency(values.currency)
        setSubmitting(false)
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div
            className={styles.form}
            role="group"
            aria-labelledby="currency-group"
          >
            {currencies.map((item, index) => (
              <Radio
                key={index}
                name="currency"
                value={item.value}
                label={item.label}
                checked={checked === item.value}
                onChange={(e) => handleChange(e, setFieldValue)}
              />
            ))}
          </div>

          <div className={styles.form__footer}>
            <Button
              disabled={currency === defaultValue}
              variant={['secondary', 'xs', '-offset-left']}
              onClick={() => {
                setChecked(defaultValue)
                setCurrency(defaultValue)
                executeHide()
              }}
              arrow={false}
              type="reset"
            >
              {translation?.clear[router.locale]}
            </Button>

            <Button
              arrow={false}
              variant={['xs']}
              disabled={checked === currency}
              type="submit"
              onClick={() => executeHide()}
            >
              {translation?.apply[router.locale]}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Currency
