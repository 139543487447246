/* eslint-disable react/prop-types */
import classNames from 'classnames'

const Columns = ({ children, variant = [], className, columnRef }) => (
  <div className={classNames('columns', className, ...variant)} ref={columnRef}>
    {children}
  </div>
)

export default Columns
