import styles from './styles.module.scss'
import { withModifiers } from '../../../helpers/withModifiers'
import classNames from 'classnames'
import { useState, useEffect } from 'react'

const withDisabled = (Component) =>
  function Wrapper(props) {
    const [ready, setReady] = useState(false)
    useEffect(() => {
      setReady(true)
    }, [])

    if (props.disabled && ready) {
      return (
        <div
          className={classNames(
            props.className,
            withModifiers('disabled', props.variant, styles)
          )}
        >
          <Component {...props} />
        </div>
      )
    }
    return <Component {...props} />
  }

export default withDisabled
