import styles from './styles.module.scss'
import Accordion from '../../../../../../elements/Accordion'
import Rentals from '../Rentals'
import Link from 'next/link'
import Button from '../../../../../../elements/Button'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useState } from 'react'
import Hamburger from '../../../../../../elements/Hamburger'
import { useRouter } from 'next/router'
import { withModifiers } from '../../../../../../helpers/withModifiers'

const Menu = ({ locations, realEstateLocations, translations }) => {
  const responsive = useResponsive()
  const [menuOpen, setMenuOpen] = useState(false)
  const router = useRouter()
  const [change, setChange] = useState(false)

  return (
    <Hamburger
      translations={translations}
      setMenuOpen={setMenuOpen}
      changeState={change}
      trigger={
        <>
          <div className={styles.menu__hamburger}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          {!responsive.lessThan.xs && (
            <div className={styles.menu__label}>
              {menuOpen
                ? translations.close[router.locale]
                : translations.menu[router.locale]}
            </div>
          )}
        </>
      }
    >
      <Accordion title={translations.rentals[router.locale]}>
        <Rentals
          translations={translations}
          locations={locations}
          setChange={setChange}
        />
      </Accordion>

      <Accordion title={translations.realEstate[router.locale]}>
        <Rentals
          translations={translations}
          locations={realEstateLocations}
          setChange={setChange}
          defaultUrl="real-estate/search"
        />
      </Accordion>

      <div
        className={withModifiers(
          'menu__item',
          [router.pathname === '/guest-experience' && 'current'],
          styles
        )}
      >
        <Link href="/guest-experience">
          <a>{translations.guestExperience[router.locale]}</a>
        </Link>
      </div>

      <div
        className={withModifiers(
          'menu__item',
          [router.pathname === '/stories' && 'current'],
          styles
        )}
      >
        <Link href="/stories">
          <a>{translations.stories[router.locale]}</a>
        </Link>
      </div>

      <Button href="/real-estate/search" className={styles.menu__button}>
        {translations.buyOrSellWithUs[router.locale]}
      </Button>
    </Hamburger>
  )
}

export default Menu
