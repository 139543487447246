import styles from './styles.module.scss'
import DropdownCaret from '/public/icons/dropdown-caret.svg'
import { useState, useMemo } from 'react'
import { withModifiers } from '../../helpers/withModifiers'

const Accordion = ({
  title,
  children,
  variant = [],
  index,
  openKey,
  setOpenKey
}) => {
  const [open, setOpen] = useState(false)
  const isLarge = variant.includes('large')

  useMemo(() => {
    if (setOpenKey) {
      if (index === openKey) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
  }, [openKey])

  return (
    <div
      className={withModifiers(
        'accordion',
        [open && 'is-open', ...variant],
        styles
      )}
    >
      <div
        onClick={() => {
          if (setOpenKey) {
            setOpenKey(index)
            if (openKey === index) {
              setOpenKey(null)
            }
          } else {
            setOpen(!open)
          }
        }}
        className={styles.accordion__trigger}
      >
        <div className={styles.accordion__title}>{title && title}</div>

        {isLarge ? (
          <div className={styles.accordion__plus}>
            <div className={styles.accordion__line}></div>
            <div className={styles.accordion__line}></div>
            <div></div>
          </div>
        ) : (
          <DropdownCaret className={styles.accordion__caret} />
        )}
      </div>

      <div className={styles.accordion__content}>{children}</div>
    </div>
  )
}

export default Accordion
