/* eslint-disable no-undef */
import Head from 'next/head'
import dynamic from 'next/dynamic'
const CTA = dynamic(() => import('../../pages/home/CTA'))
import Header from '../../general/Header'
const Footer = dynamic(() => import('../../general/Footer'))
import { withModifiers } from '../../helpers/withModifiers'
import styles from './styles.module.scss'
const Modal = dynamic(() => import('../components/Modal'))
import { text_truncate } from '../../helpers/text_truncate'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Container from '../../elements/Container'

const Page = ({
  children,
  meta,
  footer = true,
  variant = [],
  headerTranslation,
  footerTranslation,
  ctaTranslation
}) => {
  const router = useRouter()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => setLoaded(true), [])

  const canonicalUrl = (
    `${process.env.NEXT_PUBLIC_DOMAIN_URL}` + router.asPath
  ).split('?')[0]

  return (
    <main className={withModifiers('main', variant, styles)}>
      <Head>
        {meta.title && (
          <>
            <title>{meta.title}</title>

            <meta name="twitter:title" content={meta.title} />

            <meta property="og:title" content={meta.title} />
          </>
        )}

        <link rel="canonical" href={canonicalUrl} />

        {meta.description && (
          <>
            <meta
              name="description"
              content={text_truncate(meta.description, 150, '...')}
            />

            <meta
              property="og:description"
              content={text_truncate(meta.description, 150, '...')}
            />

            <meta
              name="twitter:description"
              content={text_truncate(meta.description, 150, '...')}
            />
          </>
        )}

        {meta.image ? (
          <>
            <meta property="og:image" content={meta.image} />

            <meta name="twitter:image" content={meta.image} />

            <meta property="og:image:secure_url" content={meta.image} />
          </>
        ) : (
          <>
            <meta
              name="image"
              property="og:image"
              content="/manifest/android-chrome-512x512.png"
            />

            <meta
              name="twitter:image"
              content="/manifest/android-chrome-512x512.png"
            />
            <meta
              property="og:image:secure_url"
              content="/manifest/android-chrome-512x512.png"
            />
          </>
        )}

        {meta.description && (
          <meta name="twitter:image:alt" content={meta.description} />
        )}
      </Head>
      <Container variant={['-xxl', '-p0']}>
        <Header translations={headerTranslation} />

        <div className={styles.main__content}>
          {children}

          {variant.includes('one-section') && (
            <div>
              <div className={styles.main__circle}></div>
              <div className={styles.main__circle}></div>
              <div className={styles.main__circle}></div>
            </div>
          )}
        </div>

        {footer && loaded && <CTA translations={ctaTranslation} />}

        <Modal translations={headerTranslation} />

        {loaded && footerTranslation && (
          <Footer translations={footerTranslation} />
        )}
      </Container>
    </main>
  )
}

export default Page
