import Dropdown from '../../../../../../elements/Dropdown'
import Button from '../../../../../../elements/Button'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Spanish from '/public/icons/flags/ES.svg'
import French from '/public/icons/flags/FR.svg'
import US from '/public/icons/flags/US.svg'
import Nederlands from '/public/icons/flags/NL.svg'
import styles from './styles.module.scss'
import { useResponsive } from '@farfetch/react-context-responsive'

const Languages = () => {
  const languages = [
    { icon: <Spanish />, short: 'es' },
    { icon: <French />, short: 'fr' },
    { icon: <US />, short: 'en' },
    { icon: <Nederlands />, short: 'nl' }
  ]
  const responsive = useResponsive()
  const isMobile = responsive.lessThan.sm

  const [currentLanguage, setCurrentLanguage] = useState(<US />)
  const router = useRouter()
  const { pathname, asPath, query } = router

  useEffect(() => {
    const matchingLanguage = languages.find(
      (lang) => lang.short === router.locale
    )
    if (matchingLanguage) {
      setCurrentLanguage(matchingLanguage.icon)
      // router.reload()
    }
  }, [router.locale])

  return (
    <Dropdown
      variant={['center', 'p-md']}
      arrow={!isMobile}
      title={<span className={styles.language}>{currentLanguage}</span>}
      aria-label="Current Language"
      buttonVariant={['align-middle']}
    >
      <div className={styles.languages}>
        {languages.map((language, index) => (
          <Button
            aria-label={`Change language to ${language.short}`}
            arrow={false}
            onClick={() => {
              router.push({ pathname, query }, asPath, {
                locale: language.short
              })
              setTimeout(() => router.reload(), 1000)
            }}
            key={`language-${language.short}`}
            variant={['label', index !== 0 && 'm-l-xs']}
          >
            {language.icon}
          </Button>
        ))}
      </div>
    </Dropdown>
  )
}

export default Languages
