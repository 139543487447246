import styles from './styles.module.scss'
import rentals from './rentals'
import { withModifiers } from '../../../../../../helpers/withModifiers'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSearchContext } from '../../../../../../../providers/SearchContext'
import Link from 'next/link'
import classNames from 'classnames'

const Rentals = ({
  setActive,
  setCloseClick,
  setChange,
  locations,
  translations,
  defaultUrl = 'vacation-rentals'
}) => {
  const router = useRouter()
  const { setSearchActive, setSearch, initialValues } = useSearchContext()

  useEffect(() => {
    let elements = 0
    rentals.main.map((item) => {
      if (item.link.replace('vacation-rentals', defaultUrl) === router.asPath) {
        elements = elements + 1
      }
    })

    locations &&
      locations.map((column) =>
        column.items.map((item) => {
          if (
            item.link.replace('vacation-rentals', defaultUrl) === router.asPath
          ) {
            elements = elements + 1
          }
        })
      )

    if (elements === 0) {
      setActive && setActive(false)
    } else {
      setSearchActive(false)
      setActive && setActive(true)
    }
  }, [])

  const executeHide = () => {
    if (setCloseClick) {
      setCloseClick(true)
      setTimeout(() => setCloseClick(false), 20)
    }

    if (setChange) {
      setChange(true)
      setTimeout(() => setChange(false), 20)
    }
  }

  return (
    <div className={styles.rentals}>
      <div className={styles.rentals__column}>
        <ul className={styles.rentals__list}>
          {defaultUrl !== 'vacation-rentals' && (
            <li
              className={withModifiers(
                'rentals__listItem',
                [router.asPath === `/real-estate` && 'is-active', 'main'],
                styles
              )}
            >
              <Link href={`/real-estate`}>
                <a className={styles.rentals__link}>
                  {translations.realEstate[router.locale]}
                </a>
              </Link>
            </li>
          )}

          {rentals.main.map((item, index) => {
            let shouldDisplay

            if (item.onBoth) {
              shouldDisplay = true
            } else if (item.onlyVacationRentals) {
              shouldDisplay = defaultUrl === 'vacation-rentals'
            } else {
              shouldDisplay = defaultUrl !== 'vacation-rentals'
            }

            return (
              shouldDisplay && (
                <li
                  className={classNames(
                    withModifiers(
                      'rentals__listItem',
                      [
                        router.asPath ===
                          item.link.replace('vacation-rentals', defaultUrl) &&
                          'is-active'
                      ],
                      styles
                    ),
                    item.disabled && 'disabled'
                  )}
                  key={index}
                >
                  <Link
                    href={item.link.replace('vacation-rentals', defaultUrl)}
                  >
                    <a
                      onClick={() => executeHide()}
                      className={styles.rentals__link}
                    >
                      {item.title[router.locale]}
                    </a>
                  </Link>
                </li>
              )
            )
          })}

          <li
            className={withModifiers(
              'rentals__listItem',
              [router.asPath === `/${defaultUrl}` && 'is-active', 'last'],
              styles
            )}
          >
            <Link href={`/${defaultUrl}`}>
              <a
                onClick={() => {
                  setSearch(initialValues)
                  executeHide()
                }}
                className={styles.rentals__link}
              >
                {defaultUrl === 'vacation-rentals'
                  ? translations.allRentals[router.locale]
                  : translations.allListings[router.locale]}
              </a>
            </Link>
          </li>
        </ul>
      </div>

      {locations &&
        locations.map((column, index) => (
          <div className={styles.rentals__column} key={`column-${index}`}>
            <span className={styles.rentals__title}>
              {column.title[router.locale]}
            </span>

            <div
              className={
                column.items.length > 6
                  ? styles.rentals__grid
                  : styles.rentals__flex
              }
            >
              {column.items.map((item, index) => (
                <Link key={`link-${index}`} href={item.link}>
                  <a
                    onClick={() => executeHide()}
                    className={withModifiers(
                      'rentals__link',
                      [router.asPath === item.link && 'is-active'],
                      styles
                    )}
                  >
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        ))}
    </div>
  )
}

export default Rentals
