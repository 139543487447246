import { Formik, Form, Field } from 'formik'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Dates from './components/Dates'
import styles from './styles.module.scss'
import Button from '../Button'
import Bedroom from './components/Bedrooms'
import CustomField from '../form/CustomField'
import IconListing from '/public/icons/icon-listing.svg'
import { useResponsive } from '@farfetch/react-context-responsive'
import classNames from 'classnames'
import { useSearchContext } from '../../../providers/SearchContext'
import { withModifiers } from '../../helpers/withModifiers'
import isEmpty from 'lodash-es/isEmpty'
import IconHomeType from '/public/icons/icon-homeType.svg'
import IconPriceRange from '/public/icons/icon-price-range.svg'
import Price from './components/Price'
import HomeType from './components/HomeType'
import Location from './components/Location'

const DateSearch = ({
  optional = false,
  custom = false,
  shadowRef,
  setIsActive,
  button,
  price,
  target = 'vacation-rentals',
  form,
  translations
}) => {
  const router = useRouter()
  const {
    search,
    setSearch,
    reset,
    initialValues,
    setSearchActive,
    setOffsetable
  } = useSearchContext()
  const [prepend, setPrepend] = useState(translations.listing[router.locale])
  const [listing, setListing] = useState(search.listing)
  const [priceActive, setPriceActive] = useState(false)
  const [calendarActive, setCalendarActive] = useState(false)
  const [bedroomActive, setBedroomActive] = useState(false)
  const [listingActive, setListingActive] = useState(false)
  const [filtersActive, setFiltersActive] = useState(false)
  const [searchedUrl, setSearchedUrl] = useState('vacation-rentals')
  const responsive = useResponsive()

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    // Update the search state based on the presence of priceActive
    const newSearchValues = priceActive
      ? { ...initialValues, ...values }
      : {
          ...initialValues,
          ...values,
          priceStart: null,
          priceEnd: null
        }

    setSearch(newSearchValues)

    let reroute =
      target === 'vacation-rentals' ? `/${target}` : `/${target}/search`
    if (searchedUrl !== 'vacation-rentals') {
      reroute = '/real-estate/search'
    }

    const filteredParams = Object.fromEntries(
      Object.entries(newSearchValues).filter(([key, value]) => {
        return value != null && !(key === 'sorting' && value === 'default')
      })
    )

    if (router.pathname !== reroute) {
      router.push({
        pathname: reroute,
        query: filteredParams
      })
    }

    setSubmitting(false)
  }

  const formInitialValues = {
    startDate: initialValues.startDate,
    endDate: initialValues.endDate,
    bedrooms: initialValues.bedrooms,
    listing: initialValues.listing,
    homeType: initialValues.homeType,
    amenities: initialValues.amenities,
    priceStart: initialValues.priceStart,
    priceEnd: initialValues.priceEnd,
    location: initialValues.location
  }

  useEffect(() => {
    if (router.asPath === '/') {
      setSearch(initialValues)
      setOffsetable(0)
    }
  }, [])

  useEffect(() => {
    reset && setListing(initialValues.listing)
    reset && setPrepend(translations.listing[router.locale])
  }, [reset, router])

  useEffect(() => {
    setPrepend(translations.listing[router.locale])
  }, [router.locale])

  useEffect(() => {
    setIsActive &&
      setIsActive(
        calendarActive ||
          bedroomActive ||
          listingActive ||
          priceActive ||
          filtersActive
      )
  }, [calendarActive, bedroomActive, listingActive, priceActive, filtersActive])

  return (
    <div className={classNames(styles.search, custom ? styles.custom : '')}>
      <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => {
          useEffect(() => {
            setFieldValue('listing', search.listing)
            setListing(search.listing)
            search.listing !== ''
              ? setPrepend('')
              : setPrepend(translations.listing[router.locale])
          }, [search.listing])

          let buttonActive =
            values.bedrooms ||
            values.startDate ||
            values.endDate ||
            values.listing !== '' ||
            !isEmpty(values.homeType) ||
            !isEmpty(values.location) ||
            values.startDate ||
            values.endDate ||
            (values.priceStart && values.priceStart !== 0) ||
            (values.priceEnd && values.priceEnd !== price) ||
            !isEmpty(values.amenities) ||
            false
          return (
            <Form className={styles.form}>
              <div className={styles.wrapper}>
                {form.map((field, index) => {
                  switch (field) {
                    case 'homeType': {
                      return (
                        <Field
                          key={`field-${index}`}
                          values={values}
                          shadowRef={shadowRef}
                          component={HomeType}
                          target={target}
                          translations={translations}
                          prependIcon={<IconHomeType width={26} height={24} />}
                          width={responsive.lessThan.md ? 7.1 : 9}
                          setFiltersActive={setFiltersActive}
                        />
                      )
                    }
                    case 'priceRange': {
                      if (price) {
                        return (
                          <Field
                            key={`field-${index}`}
                            values={values}
                            shadowRef={shadowRef}
                            component={Price}
                            price={price}
                            translation={translations.priceRange[router.locale]}
                            prependIcon={
                              <IconPriceRange width={26} height={24} />
                            }
                            width={responsive.lessThan.md ? 8 : 11}
                            setPriceActive={setPriceActive}
                          />
                        )
                      }
                      return
                    }
                    case 'dates': {
                      return (
                        <Field
                          key={`field-${index}`}
                          setFieldValue={setFieldValue}
                          values={values}
                          shadowRef={shadowRef}
                          component={Dates}
                          custom={custom}
                          icon
                          translations={translations}
                          setCalendarActive={setCalendarActive}
                        />
                      )
                    }
                    case 'location': {
                      return (
                        <Field
                          key={`field-${index}`}
                          setFieldValue={setFieldValue}
                          values={values}
                          shadowRef={shadowRef}
                          component={Location}
                          custom={custom}
                          icon
                          setCalendarActive={setCalendarActive}
                        />
                      )
                    }
                    case 'bedrooms': {
                      return (
                        <Field
                          translations={translations}
                          key={`field-${index}`}
                          values={values}
                          shadowRef={shadowRef}
                          setFieldValue={setFieldValue}
                          component={Bedroom}
                          custom={custom}
                          icon
                          width={responsive.lessThan.md ? 7.8 : 11}
                          setBedroomActive={setBedroomActive}
                        />
                      )
                    }
                    case 'listings': {
                      return (
                        <CustomField
                          aria-label="Search listing by name"
                          key={`field-${index}`}
                          name="listing"
                          prependIcon={<IconListing width={26} height={26} />}
                          type="text"
                          width={
                            listing !== ''
                              ? responsive.lessThan.md
                                ? 5
                                : 12.93
                              : responsive.lessThan.md
                              ? 5
                              : 10.876
                          }
                          variant={[
                            responsive.lessThan.sm && 'full-width',
                            'optional'
                          ]}
                          translations={translations}
                          optional={optional}
                          value={listing}
                          onChange={(e) => {
                            setFieldValue('listing', e.target.value)
                            setListing(e.target.value)
                            setListingActive(e.target.value)
                          }}
                          isActive={listing !== ''}
                          prepend={prepend}
                          onFocus={() => {
                            setPrepend('')
                          }}
                          onBlur={() => {
                            listing === '' &&
                              setPrepend(translations.listing[router.locale])
                          }}
                        />
                      )
                    }
                  }
                })}

                <Button
                  variant={[
                    'xs',
                    'secondary',
                    'gold',
                    '-offset-right',
                    responsive.lessThan.sm && 'full-width'
                  ]}
                  onClick={() => {
                    setSearchActive &&
                      target === 'vacation-rentals' &&
                      setSearchActive(true)
                    setSearchedUrl('vacation-rentals')
                  }}
                  className={withModifiers(
                    'submit',
                    [!buttonActive && 'disabled'],
                    styles
                  )}
                >
                  <span className={styles.submit__label}>
                    {translations.search[router.locale]}
                  </span>
                </Button>
              </div>

              {button && (
                <Button
                  className={styles.button}
                  variant={['secondary']}
                  onClick={() => {
                    setSearchedUrl('real-estate')
                    setSearchActive &&
                      target !== 'vacation-rentals' &&
                      setSearchActive(true)
                  }}
                >
                  {translations.buyOrSell[router.locale]}
                </Button>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default DateSearch
