import CustomField from '../form/CustomField'
import styles from './styles.module.scss'
import { useState, useRef, useEffect } from 'react'
import Button from '../Button'
import { withModifiers } from '../../helpers/withModifiers'
import Scroll from 'react-scroll'
import { useResponsive } from '@farfetch/react-context-responsive'

const DropdownField = ({
  variant = [],
  children,
  isActive,
  shadowRef,
  onClear,
  onApply,
  angle = true,
  footer = true,
  disableClear,
  disableApply,
  scrollTop = false,
  clearTranslation,
  applyTranslation,
  triggerClose,
  ...props
}) => {
  const responsive = useResponsive()
  const isLaptop = responsive.lessThan.xl
  const wrapperRef = useRef(null)
  const [show, setShow] = useState(false)
  const isSimple = variant.length !== 0 && variant.includes('content-simple')
  const scroll = Scroll.animateScroll

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)
  useEffect(() => {
    triggerClose && setShow(false)
  }, [triggerClose])

  useEffect(() => {
    shadowRef && show
      ? shadowRef?.current?.classList.add('isVisible')
      : shadowRef?.current?.classList.remove('isVisible')
  }, [shadowRef, show])

  return (
    <div
      ref={wrapperRef}
      className={withModifiers('field', variant, styles)}
      role="group"
      aria-labelledby="formik-group"
    >
      {isSimple ? (
        !isLaptop && <div className={styles.field__label}>{props.value}</div>
      ) : (
        <CustomField
          variant={variant}
          type="text"
          readOnly
          angle={angle}
          isActive={isActive || show}
          onClick={() => setShow(!show)}
          {...props}
        />
      )}

      <div
        className={withModifiers(
          'field__content',
          [show && 'is-active'],
          styles
        )}
      >
        <div className={styles.field__wrapper}>
          {isSimple && isLaptop && (
            <div className={styles.field__label}>{props.value}</div>
          )}

          {children}

          {footer && !isSimple && (
            <div className={styles.field__footer}>
              <Button
                onClick={() => {
                  onClear && onClear()
                  setShow(false)
                  if (scrollTop) {
                    scroll.scrollToTop()
                  }
                }}
                disabled={disableClear}
                arrow={false}
                variant={['xs', 'secondary', '-offset-left']}
              >
                {clearTranslation}
              </Button>

              {onApply && (
                <Button
                  variant={['xs']}
                  arrow={false}
                  onClick={() => {
                    onApply && onApply()
                    setShow(false)
                    if (scrollTop) {
                      scroll.scrollToTop()
                    }
                  }}
                  type="submit"
                  disabled={disableApply}
                  className={styles.footer}
                >
                  {applyTranslation}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DropdownField
