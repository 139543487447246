import Radio from '/components/elements/form/Radio'
import { useState, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { useResponsive } from '@farfetch/react-context-responsive'
import isEmpty from 'lodash-es/isEmpty'
import { useSearchContext } from '/providers/SearchContext'
import axios from '/components/configs/axios'
import { useRouter } from 'next/router'
import Grid from '/components/elements/Grid'
import classNames from 'classnames'
import CustomField from '../../../form/CustomField'
import IconLocation from '/public/icons/icon-location.svg'

const Locations = ({ form: { setFieldValue } }) => {
  const { search, setSearch } = useSearchContext()
  const responsive = useResponsive()
  const router = useRouter()
  const wrapperRef = useRef(null)

  const [checked, setChecked] = useState(search.location)
  const [label, setLabel] = useState(search.location)
  const [locations, setLocations] = useState(false)
  const isLaptop = responsive.lessThan.xl
  const isLandscape = responsive.lessThan.sm
  const isTablet = responsive.lessThan.md
  const isMobile = responsive.lessThan.xs
  const isRealEstate = router.pathname.includes('real-estate')
  const [show, setShow] = useState(false)

  const replaceInArray = function (str) {
    return str.replace(/-/g, ' ')
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleChange = (e) => {
    var updatedList = [...checked]
    if (e.target.checked) {
      updatedList = [...checked, e.target.value]
    } else {
      updatedList.splice(checked.indexOf(e.target.value), 1)
    }
    setChecked(updatedList)
    isLaptop && setFieldValue('location', updatedList)
  }

  const handleSubmit = () => {
    setSearch({ ...search, location: checked })
    setLabel(checked)
    setFieldValue('location', checked)
  }

  useEffect(() => {
    if (!isEmpty(search.location)) {
      setChecked(search.location)
      setLabel(search.location.map(replaceInArray).join(', '))
    } else {
      setLabel([])
    }
  }, [search])

  useEffect(() => {
    axios
      .get(
        isRealEstate ? 'real-estate/neighbourhood' : 'listings/neighbourhood'
      )
      .then((res) => setLocations(res.data))
      .catch((err) => console.error(err))
  }, [router.locale])

  useEffect(() => {
    handleSubmit()
  }, [checked])

  useOutsideAlerter(wrapperRef)

  if (locations) {
    return (
      <div ref={wrapperRef}>
        <CustomField
          aria-label="Select Location"
          value={label?.length === 0 ? 'Locations' : label}
          onClick={() => setShow(!show)}
          width={responsive.lessThan.md ? 6 : 9}
          prependIcon={<IconLocation width={26} height={26} />}
          isActive={checked?.length !== 0}
          variant={[responsive.lessThan.sm && 'full-width']}
          // {...props}
        />
        <div className={classNames(styles.field, show && styles.isShown)}>
          <div className={styles.location}>
            {locations.map(
              (item, index) =>
                !isEmpty(item.options) && (
                  <div className={styles.location__column} key={index}>
                    <div className={styles.location__title}>{item.title}</div>
                    <Grid
                      variant={[
                        isMobile
                          ? 'grid-1'
                          : isLandscape
                          ? 'grid-2'
                          : isTablet
                          ? 'grid-2'
                          : item.options.length > 5
                          ? 'rows-6'
                          : 'rows-5',
                        'rows-2xs'
                      ]}
                    >
                      {item.options.map((radio, number) => (
                        <Radio
                          key={`${index}-${number}`}
                          name="location"
                          variant={['is-close', 'small']}
                          checkbox
                          value={radio.value}
                          label={radio.label}
                          checked={checked?.includes(radio.value)}
                          onChange={(e) => handleChange(e)}
                        />
                      ))}
                    </Grid>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    )
  }
  return
}

export default Locations
