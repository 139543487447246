/* eslint-disable react/prop-types */
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import styles from './styles.module.scss'
import { withModifiers } from '../../helpers/withModifiers'
import LazyImage from '../LazyImage'
const CloudflareVideo = dynamic(() => import('../CloudflareVideo'))

const Section = ({
  children,
  className,
  variant = [],
  bg,
  video = false,
  cloudflare,
  separator = false,
  sectionRef,
  grayscale = false,
  priority = false,
  ...props
}) => (
  <section
    ref={sectionRef}
    className={classNames(
      withModifiers(
        'section',
        [...variant, separator && 'has-separator', bg && 'with-bg'],
        styles
      ),
      className
    )}
    {...props}
  >
    {bg && (
      <LazyImage
        objectFit="cover"
        objectPosition="center"
        layout="fill"
        key="bg"
        src={bg}
        priority={priority}
        sizes="(min-width: 1400) 100vw, (max-width: 1400) 50vw"
        alt="section background image"
      />
    )}

    {(cloudflare || video) && (
      <div
        className={withModifiers(
          'section__video',
          [grayscale && 'grayscale'],
          styles
        )}
      >
        <CloudflareVideo autoplay loop muted src={cloudflare || video} />

        <div className={styles.section__overlay}></div>
      </div>
    )}
    {separator && <div className={styles.section__separator}></div>}
    {children}
  </section>
)

export default Section
