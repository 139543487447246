import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import DropdownCaret from '/public/icons/dropdown-caret.svg'
import Button from '../Button'
import { withModifiers } from '../../helpers/withModifiers'
import { useResponsive } from '@farfetch/react-context-responsive'
import { useRouter } from 'next/router'

const Dropdown = ({
  children,
  title,
  arrow = true,
  custom = false,
  disabled = false,
  active = false,
  handleClose = false,
  variant = [],
  buttonVariant = [],
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState(0)
  const contentRef = useRef(null)
  const buttonRef = useRef(null)
  const wrapperRef = useRef(null)
  const responsive = useResponsive()
  const router = useRouter()

  const isTouch =
    typeof window !== 'undefined' &&
    window?.matchMedia('(pointer: coarse)').matches

  const handleOpen = () => {
    setOpen(!open)
    setTimeout(() => {
      !open && contentRef.current.focus()
    }, 100)
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (
        event.key === 'Enter' &&
        document.activeElement === buttonRef.current
      ) {
        event.preventDefault()
        setOpen(true)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  // Function to calculate and set the left position of the wrapper
  const positionDropdown = () => {
    const centeredElement = wrapperRef.current
    const buttonElement = buttonRef.current

    if (!centeredElement) return

    const buttonRect = buttonElement.getBoundingClientRect()
    const centeredElementRect = centeredElement.getBoundingClientRect()
    const elementWidth = centeredElement.offsetWidth
    const viewportWidth = window.innerWidth

    if (
      (centeredElementRect.right === viewportWidth + 2 &&
        centeredElementRect.left !== 0) ||
      centeredElementRect.left < 0 ||
      centeredElementRect.right > viewportWidth
    ) {
      if (
        elementWidth + elementWidth / 2 > viewportWidth ||
        buttonRect.right + elementWidth / 2 > viewportWidth
      ) {
        if (buttonRect.left < elementWidth / 2) {
          centeredElement.style.left = `-${buttonRect.left - 2}px`
          centeredElement.style.transform = 'none'
          centeredElement.style.right = 'unset'
        }
        if (
          viewportWidth - buttonRect.right < elementWidth / 2 &&
          getComputedStyle(centeredElement).getPropertyValue('transform') !==
            'none'
        ) {
          centeredElement.style.right = `-${
            viewportWidth - buttonRect.right - 20
          }px`
          centeredElement.style.left = 'unset'
          centeredElement.style.transform = 'none'
        }
      }
    }
  }

  useEffect(() => {
    handleClose && setOpen(!open)
  }, [handleClose])

  useEffect(() => {
    positionDropdown()
  }, [responsive, width, router])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width)
    })
    observer.observe(wrapperRef.current)
    return () => wrapperRef.current && observer.unobserve(wrapperRef.current)
  }, [])

  return (
    <div
      onMouseEnter={() => !handleClose && setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className={withModifiers(
        'dropdown',
        [
          custom ? 'custom' : '',
          active && 'is-active',
          open && 'is-open',
          disabled && 'disabled',
          ...variant
        ],
        styles
      )}
    >
      <Button
        buttonRef={buttonRef}
        disabled={disabled}
        className={styles.dropdown__button}
        onClick={() => {
          if (isTouch) {
            handleOpen()
          } else if (open) {
            handleOpen()
          }
        }}
        variant={['label', ...buttonVariant]}
        arrow={false}
        {...props}
      >
        {title}

        {arrow && <DropdownCaret className={styles.dropdown__caret} />}
      </Button>

      <div ref={wrapperRef} className={styles.dropdown__wrapper}>
        <div ref={contentRef} className={styles.dropdown__content}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
