/* eslint-disable react/prop-types */
import styles from './styles.module.scss'
import IconArrow from '/public/icons/icon-cta.svg'
import classNames from 'classnames'
import { useResponsive } from '@farfetch/react-context-responsive'
import { withModifiers } from '../../helpers/withModifiers'
import withDisabled from './wrappers/withDisabled'
import Link from 'next/link'

const Button = ({
  variant = [],
  children,
  className,
  disabled,
  arrow = true,
  type,
  prepend,
  buttonRef,
  onClick,
  href,
  name = 'action button',
  appendIcon = false,
  ...props
}) => {
  const responsive = useResponsive()

  if (href) {
    return (
      <Link href={href}>
        <a
          className={classNames(
            className,
            withModifiers('button', variant, styles)
          )}
          ref={buttonRef}
          type={type}
          disabled={disabled}
          onClick={onClick ? onClick : undefined}
          {...props}
        >
          {prepend && <div className={styles.button__prepend}>{prepend}</div>}

          <div className={styles.button__label}>{children}</div>

          {arrow && !appendIcon && (
            <IconArrow
              width={responsive.lessThan.xs ? 17 : 23}
              className={styles.button__arrow}
            />
          )}

          {appendIcon && (
            <div className={styles.button__append}>{appendIcon}</div>
          )}
        </a>
      </Link>
    )
  }

  return (
    <button
      className={classNames(
        className,
        withModifiers('button', variant, styles)
      )}
      href={href}
      ref={buttonRef}
      type={type}
      name={name}
      disabled={disabled}
      onClick={onClick ? onClick : undefined}
      {...props}
    >
      {prepend && <div className={styles.button__prepend}>{prepend}</div>}

      <div className={styles.button__label}>{children}</div>

      {arrow && !appendIcon && (
        <IconArrow
          width={responsive.lessThan.xs ? 17 : 23}
          className={styles.button__arrow}
        />
      )}

      {appendIcon && <div className={styles.button__append}>{appendIcon}</div>}
    </button>
  )
}

export default withDisabled(Button)
